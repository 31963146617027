<template>
	<div id="fileListPreview">
		<div class="top">
			<div class="title">全部文件<i></i></div>
			<div class="content">
				<p v-if="projectTree">共 {{projectTree.length}} 项</p>
				<el-popover class="markers" popper-class="markers" placement="bottom-start" width="500" trigger="hover">
					<div class="popoverCont">
						<p><b>视频文件解析问题：</b>为防止视频文件自身问题或传输过程中的编码问题 造成视频文件解析异常，视频文件 请先压缩 以压缩文件格式上传</p>
						<p><b>文件上传成功率：</b>为确保文件上传成功率 以及减少传输时长 建议单个文件大小控制在400M以内</p>
						<p><b>文档与图片预览速度：</b>为确保文档与图片的预览速度 建议文档与图片单个文件大小控制在20M以内</p>
					</div>
					<p slot="reference">上传提示:<i class="el-icon-s-opportunity"></i></p>
				</el-popover>
				<el-popover class="markers" popper-class="markers" placement="bottom-start" width="500" trigger="hover">
					<p slot="reference">文件上传要求:<i class="el-icon-question"></i></p>
					<div class="popoverCont">
						<p v-for="item in projectTree" :key="item.id">
							<b>{{item.folderName}}：</b>
							{{item.ifFileRequired=="Y"?"文件必传":"文件非必传"}}
							{{item.fileUploadRequirement}}
						</p>
					</div>
				</el-popover>
				<el-popover class="markers" popper-class="markers" placement="bottom-start" width="500" trigger="hover">
					<p slot="reference">图片上传要求:<i class="el-icon-question"></i></p>
					<div class="popoverCont">
						<p v-for="item in projectTree" :key="item.id">
							<b>{{item.folderName}}：</b> 图片张数限制
							{{item.minFileNum}} - {{item.maxFileNum}}
						</p>
					</div>
				</el-popover>
				<el-popover class="markers" popper-class="markers" placement="bottom-start" trigger="hover">
					<p slot="reference">文件审核状态(全部文件):<i class="el-icon-info"></i></p>
					<div class="popoverCont">
						<p><b>未上传文件</b></p>
					</div>
				</el-popover>
			</div>
			<div class="tableBotions">
				<!-- <el-button type="text" @click="bulkDownload">批量下载</el-button> -->
				<el-pagination :disabled="loading" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="current" :page-sizes="[10, 20, 50, 100]" :page-size="size"
					layout="total, sizes, prev, pager, next" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-table v-loading="loading" element-loading-text="正在下载中···" ref="multipleTable" :data="projectTree"
			tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55" align="center"></el-table-column>
			<el-table-column label="名称" align="left" width="200">
				<template slot-scope="scope">
					<span class="folderName" @click="tableCellClick(scope.row)">
						<img src="../../../assets/imgs/i_file.svg" alt="">
						{{scope.row.folderName}}
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="ifFileRequired" label="是否必传" align="center" width="100">
				<template slot-scope="scope">
					<span v-if="scope.row.ifFileRequired == 'Y'"> 是 </span>
					<span v-else> 是 </span>
				</template>
			</el-table-column>
			<el-table-column prop="ifFileRequired" label="文件审核状态" align="center" width="110">
				<template slot-scope="scope">
					<span>
						<!-- {{scope.row.ifFileRequired}} -->
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="fileUploadRequirement" label="文件上传要求" align="left"></el-table-column>
			<el-table-column prop="" label="文件数量" align="center" width="80"></el-table-column>
			<el-table-column prop="" label="文件大小" align="center" width="80"></el-table-column>
			<el-table-column prop="" label="操作" align="center" width="140">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="UpTarget(scope.row)">去上传</el-button>
					<el-button type="text" size="small" @click="downloadFile(scope.row)">下载</el-button>
					<!-- <el-button type="text" size="small">删除</el-button> -->
					<!-- <el-button type="text" size="small">编辑</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<el-pagination :disabled="loading" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="current" :page-sizes="[10, 20, 50, 100]" :page-size="size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" background>
		</el-pagination>
		<el-dialog :visible.sync="notInfoDialog" title="重要通知" width="300px" center custom-class="notInfoDialog"
			:close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
			<p>请您严格依据双方已签订的《项目服务协议》中的附件结算资料清单要求明细表，提供对应的费用报销单据。如提供的资料不完整或资料与要求明显不相符，则该收款申请会被驳回，且无法完成正常费用结算，敬请知悉！</p>
			<el-checkbox v-model="notInfoChecked">同意并知晓</el-checkbox>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="agreeAndKnow">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	// import projectTree from '@/assets/projectTree.json';
	import {
		getDetailFolders,
		downloadFile,
		editOrderView
	} from '@/request/api'
	export default {
		name: 'fileListPreview',
		data() {
			return {
				projectTree: [],
				fileList: '',
				multipleSelection: [],
				notInfoDialog: true,
				notInfoChecked: true,
				total: 0,
				current: 1,
				size: 10,
				loading: false
			};
		},
		computed: {},
		created() {
			if (this.$route.query.isView) this.notInfoDialog = false;
			this.getDetailFolder();
		},
		mounted() {},
		methods: {
			handleSizeChange(val) {
				this.size = val;
				this.getDetailFolder();
			},
			handleCurrentChange(val) {
				this.current = val;
				this.getDetailFolder();
			},
			// 获取明细内文件夹列表
			getDetailFolder() {
				let data = {
					size: this.size,
					current: this.current,
					id: this.$route.query.id
				};
				getDetailFolders(data).then(res => {
					if (res.code == 200) {
						this.projectTree = res.data.records;
						this.total = res.data.total;
					};
				});
			},
			tableCellClick(row) {
				this.UpTarget(row)
			},
			// 下载文件
			downloadFile(row) {
				let data = {
					folderId: row.id
				};
				this.loading = true;
				this.$message({
					message: '正在下载，请稍后...',
					type: 'warning'
				});
				downloadFile(data)
					.then(res => {
						let blob = new Blob([res], {
							type: 'application/zip'
						});
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.target = '_blank';
						a.style.display = 'none';
						a.href = url;
						a.download = row.folderName;
						a.click();
						window.URL.revokeObjectURL(url);
						this.loading = false;
					})
			},
			// 知晓并同意
			agreeAndKnow() {
				if (this.notInfoChecked) {
					editOrderView({
						orderId: this.$route.query.oId
					}).then(res => {
						if (res.code == 200) {
							this.$router.replace({
								path: '/fileListPreview',
								query: {
									id: this.$route.query.id,
									oId: this.$route.query.oId,
									isView: 1
								}
							});
							this.notInfoDialog = false;
						};
					});
				} else {
					this.$message({
						message: '请勾选同意并知晓',
						type: 'warning'
					});
				};
			},
			// 批量下载
			bulkDownload() {
				if (this.multipleSelection[0]) {
					this.$message({
						message: '批量下载功能暂未开放',
						type: 'warning'
					});
				} else {
					this.$message({
						message: '请选择要下载的文件',
						type: 'warning'
					});
				};
			},
			// 上传全准备 设置上传目标
			UpTarget(row) {
				// console.log(row)
				this.$router.push({
					path: '/fileUpdataReview',
					query: {
						id: row.id,
					}
				});
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				};
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		}
	}
</script>

<style lang="scss" scoped>
	#fileListPreview {
		width: 100%;
		height: 100%;
		padding: 20px;

		.top {
			padding: 20px;
			margin-bottom: 20px;
			border-radius: 20px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
			display: flex;
			align-items: center;

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.content {
				display: flex;

				>* {
					margin-right: 20px;
				}

				.markers {
					.el-popover__reference {
						.el-icon-s-opportunity {
							color: #409EFF;
						}

						.el-icon-question {
							color: #F56C6C;
						}

						.el-icon-info {
							color: #E6A23C;
						}
					}

					.popoverCont {
						display: flex;
						flex-direction: column;

						p {
							margin-bottom: 6px;
							text-align: center;
							font-size: 14px;
							color: red;

							&:last-child {
								margin-bottom: 0px;
							}
						}
					}
				}
			}

			.tableBotions {
				padding: 0;
				flex: 1;
				display: flex;
				justify-content: flex-end;

				.el-pagination {
					margin: 0;
				}
			}
		}

		.el-table {
			.folderName {
				display: flex;
				align-items: center;

				img {
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
		}

		.el-pagination {
			text-align: center;
			margin-top: 20px;
		}
	}

	::v-deep .markers {
		.popoverCont {
			display: flex;
			flex-direction: column;

			p {
				margin-bottom: 6px;
				text-align: center;
				font-size: 14px;
				color: red;

				&:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}

	::v-deep .notInfoDialog {
		.el-dialog__body {
			padding: 0 20px;
			text-align: center;

			>p {
				margin-bottom: 10px;
				text-align: justify;
				word-break: break-all;
				text-align: justify;
			}
		}
	}
</style>